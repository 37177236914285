<!-- 申请记录 -->
<template>
  <div class="fun_contact_apply">
    <div class="wrapper">
      <div class="title_group">
        <div class="title">申请记录</div>
      </div>
      <div class="list_group" v-if="list_group">
        <ContactApplyUnit class="list_unit" :info="item" v-for="(item,index) in list_group" :key="index"></ContactApplyUnit>
      </div>

    </div>

  </div>
</template>
<script>
import ContactApplyUnit from '@/components/Contact/ApplyUnit.vue';
export default {
  components:{
    ContactApplyUnit,
  },
  data(){
    return{
      list_group: null, //申请列表
    }
  },
  computed:{
    new_apply(){
      return this.$store.state.new_apply;
    }
  },
  watch:{
    new_apply: async function(val){
      if(val){
        await this.getApplyData();
      }
    }
  },
  methods:{
    /**
     * 获取好友申请列表
     */

    async getApplyData(){
      let res = await this.$http.request('/projectFriends/friendsApplyList', {});
      let list_group = this.filterFriendsApplyList(res.contents.list);
      this.$set(this, 'list_group', list_group);

    },
    /**筛选申请好友列表信息 */
    filterFriendsApplyList(list){
      let list_obj = {};
      let list_obj_sort = []; //记录list_obj[key]的顺序
      let list_group = []; // 输入数据
      // recUserId 接收id || sendUserId 发送id
      let userId = this.$tools.getUserId();
      if(list && list.length){
        list.forEach( item => {
          let {recUserId,sendUserId} = item;
          // 获取keyid;
          let keyId = recUserId != userId ? recUserId : sendUserId;
          // 消息类型
          let keyType = recUserId != userId ? 'rec' : 'send';
          if(!list_obj[`${keyType}${keyId}`]){
            list_obj[`${keyType}${keyId}`] = item;
            list_obj_sort.push(`${keyType}${keyId}`);
          }
        })
      }
      if(list_obj_sort.length){
        list_obj_sort.forEach( item => {
          list_group.push(list_obj[item]);
        })
      }
      return list_group;
    }
  },
  async mounted(){
    await this.getApplyData();
    this.$store.commit('set_new_apply', false); //移除红点
  }

}
</script>

<style lang="scss" scoped>
.fun_contact_apply{
  width:100%;
  height: 100%;
  background-color: #f5f8fa;
  .wrapper{
    @include hidescrollbar;
    position: relative;
    z-index: 2;
    background-color: #f5f8fa;
    .title_group{
      @include bbox;
      line-height: 64px;
      padding-left: 20px;
      width:100%;
      position: sticky;
      top:0;
      background-color:#fff;
      z-index: 3;
      font-size:18px;

      font-weight:400;
      color:rgba(51,51,51,1);
    }
    .list_group{
      @include bbox;
      width:100%;
      padding: 20px;
      .list_unit{
        margin-bottom: 20px;
      }
    }
  }
}
</style>
