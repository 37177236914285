var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fun_contact_apply" }, [
    _c("div", { staticClass: "wrapper" }, [
      _vm._m(0),
      _vm.list_group
        ? _c(
            "div",
            { staticClass: "list_group" },
            _vm._l(_vm.list_group, function (item, index) {
              return _c("ContactApplyUnit", {
                key: index,
                staticClass: "list_unit",
                attrs: { info: item },
              })
            }),
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title_group" }, [
      _c("div", { staticClass: "title" }, [_vm._v("申请记录")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }