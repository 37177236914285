<!--
 * @Author       : Hugo
 * @Date         : 2020-05-26 10:47:07
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-01-21 10:47:52
 * @Description  : 申请好友消息单项
 * @FilePath     : /miaohang/src/components/Contact/ApplyUnit.vue
-->
<!-- 申请好友 -->
<template>
  <div class="contact_apply_unit">
    <div class="info_group">
      <img :src="`${$avatar_url}${info.thumbnail}`" alt="" class="avatar">
      <div class="info_box">
        <div class="name_group">
          <div class="name">{{info.userName}}</div>
          <div class="phone">{{info.phone}}</div>
        </div>
        <div class="remark">{{info.sendRemark}}</div>
      </div>
    </div>
    <div class="fun_group">
      <div class="time">{{info.createTime | formatDate}}</div>
      <div class="btn sure" v-if="info.status == 1 && is_receive" @click.stop="applyHandler(2)">接受</div>
      <div class="btn refuse" v-if="info.status == 1 && is_receive" @click.stop="applyHandler(3)">拒绝</div>
      <div class="btn added" v-if="info.status == 2">已添加</div>
      <div class="btn refused" v-if="info.status == 3 && is_receive">已拒绝</div>
      <!-- 自己是发送者 -->
      <div class="text refuse" v-if="info.status == 3 && !is_receive">对方已拒绝</div>
      <div class="btn reapply" v-if="info.status == 3 && !is_receive" @click.stop="reApply">重新申请</div>
      <div class="text wait" v-if="info.status == 1 && !is_receive">已申请</div>
      <div class="text subwait" v-if="info.status == 1 && !is_receive">请等待对方反馈</div>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    info:{
      type: Object,
      default: null,
    }
  },
  data(){
    return {
      is_receive: false, // 是否是接收方 true 是
    }
  },
  methods:{
    /**
     * 处理好友申请
     * params {Number} type 2 同意 3拒绝
     */
    async applyHandler(type){
      this.$store.commit('set_new_apply', false); //移除红点
      let data = {
        id: this.info.id,
        type,
      }
      let res = await this.$http.request('/projectFriends/friendsApplyDo',data);
      // 发送请求成功后处理
      if(res.message == 'success'){
        // 自己发送
        if(!this.is_receive){
          // 对方已拒绝
          if(this.info.status == 3){
            this.$set( this.info, 'status', 1)
            return;
          }
        // 别人发送给我
        }else{
          // 同意
          if(this.info.status == 1 && type == 2){
            this.$set( this.info, 'status', 2);
            // 刷新好友列表
            this.$store.dispatch('get_friends_list');
            return;
          }
          // 拒绝
          if(this.info.status == 1 && type == 3){
            this.$set( this.info, 'status', 3)
            return;
          }

        }
      }else{
        this.$toast({
          icon: 'none',
          title: res.message,
        })
      }

      return res
    },
    /**
     * 重新申请好友
     */
    reApply(){
      this.$store.commit('set_new_apply', false); //移除红点
      let payload = {
        ...this.info,
        userName: this.info.username,
        userId: this.info.recUserId,
      }
      this.$store.commit('to_add_friend', payload);
    }

  },
  async mounted() {
    let loginUserCode = this.$tools.getCookie('loginUserCode');
    let loginUserId = loginUserCode.userId;
    if(this.info.recUserId == loginUserId){
      this.is_receive = true;
    }
  },
}
</script>

<style lang="scss" scoped>
.contact_apply_unit{
  @include bbox;
  @include flexsb;
  align-items: flex-start;
  background:rgba(255,255,255,1);
  border-radius:10px;
  padding:20px;
  .info_group{
    @include bbox;
    @include flex;
    width:100%;
    flex:1;
    .avatar{
      flex-shrink: 0;
      width:90px;
      height:90px;
      border-radius:4px;
      background-color: #f4f5f6;
    }
    .info_box{
      @include flexsb;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      padding:0 20px;
      width:100%;
      flex:1;
      .name_group{
        .name{
          font-size:16px;

          font-weight:400;
          color:rgba(51,51,51,1);
        }
        .phone{
          padding-top: 8px;
          font-size:14px;

          font-weight:400;
          color:rgba(102,102,102,1);
        }
      }
      .remark{
        font-size:14px;

        font-weight:400;
        color:rgba(102,102,102,1);
        text-align: justify;
        text-justify: inter-ideograph;
        text-align-last: left;
      }
    }
  }
  .fun_group{
    @include flex;
    flex-direction: column;
    align-items: stretch;
    flex-shrink: 0;
    text-align: center;
    .time{
      font-size:14px;

      font-weight:400;
      color:rgba(102,102,102,1);
      padding-bottom: 8px;
      line-height: 14px;
    }
    .btn{
      @include transition;
      line-height:34px;
      border-radius:4px;
      margin-bottom: 8px;
      cursor: pointer;
      &:last-child{
        margin-bottom: 0;
      }
      &.sure, &.reapply{
        background:rgba(0,84,166,1);
        color: #fff;
        &:hover{
          background-color: #004080;
        }
      }
      &.refuse{
        background:rgba(0,84,166,0.1);
        color: $main_blue;
        &:hover{
          background:rgba(0,84,166,0.2);
        }
      }
      &.added, &.refused{
        background-color:#ccc;
        color:#fff;
      }
    }

    .text{
      font-size:16px;

      font-weight:400;
      color:$main_blue;
      &:last-child{
        padding-bottom: 0!important;
      }
      &.refuse{
        color:rgba(180,17,0,1);
        padding-bottom: 15px;
      }
      &.wait{
        padding-bottom: 7px;
      }
      &.subwait{
        font-size: 14px;
      }
    }

  }
}
</style>
