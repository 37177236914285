var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact_apply_unit" }, [
    _c("div", { staticClass: "info_group" }, [
      _c("img", {
        staticClass: "avatar",
        attrs: { src: `${_vm.$avatar_url}${_vm.info.thumbnail}`, alt: "" },
      }),
      _c("div", { staticClass: "info_box" }, [
        _c("div", { staticClass: "name_group" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.info.userName)),
          ]),
          _c("div", { staticClass: "phone" }, [_vm._v(_vm._s(_vm.info.phone))]),
        ]),
        _c("div", { staticClass: "remark" }, [
          _vm._v(_vm._s(_vm.info.sendRemark)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "fun_group" }, [
      _c("div", { staticClass: "time" }, [
        _vm._v(_vm._s(_vm._f("formatDate")(_vm.info.createTime))),
      ]),
      _vm.info.status == 1 && _vm.is_receive
        ? _c(
            "div",
            {
              staticClass: "btn sure",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.applyHandler(2)
                },
              },
            },
            [_vm._v("接受")]
          )
        : _vm._e(),
      _vm.info.status == 1 && _vm.is_receive
        ? _c(
            "div",
            {
              staticClass: "btn refuse",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.applyHandler(3)
                },
              },
            },
            [_vm._v("拒绝")]
          )
        : _vm._e(),
      _vm.info.status == 2
        ? _c("div", { staticClass: "btn added" }, [_vm._v("已添加")])
        : _vm._e(),
      _vm.info.status == 3 && _vm.is_receive
        ? _c("div", { staticClass: "btn refused" }, [_vm._v("已拒绝")])
        : _vm._e(),
      _vm.info.status == 3 && !_vm.is_receive
        ? _c("div", { staticClass: "text refuse" }, [_vm._v("对方已拒绝")])
        : _vm._e(),
      _vm.info.status == 3 && !_vm.is_receive
        ? _c(
            "div",
            {
              staticClass: "btn reapply",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.reApply.apply(null, arguments)
                },
              },
            },
            [_vm._v("重新申请")]
          )
        : _vm._e(),
      _vm.info.status == 1 && !_vm.is_receive
        ? _c("div", { staticClass: "text wait" }, [_vm._v("已申请")])
        : _vm._e(),
      _vm.info.status == 1 && !_vm.is_receive
        ? _c("div", { staticClass: "text subwait" }, [_vm._v("请等待对方反馈")])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }